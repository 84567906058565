@tailwind base;
@tailwind components;

@font-face {
  font-family: "Burowai";
  src: url(./fonts/Burowai.otf);
}
@font-face {
  font-family: "MonumentBlack";
  src: url(./fonts/PPMonumentExtended-Black.otf);
}
@font-face {
  font-family: "MonumentRegular";
  src: url(./fonts/PPMonumentExtended-Regular.otf);
}
@font-face {
  font-family: "MonumentLight";
  src: url(./fonts/PPMonumentExtended-Light.otf);
}

#flashlight {
  position: absolute;
  left: -100%;
  top: -100%;

  height: 300%;
  width: 300%;

  background: radial-gradient(
    circle at 50% 50%,
    rgba(0, 0, 0, 0) 2%,
    rgba(0, 0, 0, 0.98) 6%
  ); /*comment that to see all screen*/
  filter: blur(4px);

  transition: left 0.5s ease-in-out, top 0.5s ease-in-out,
    height 0.5s ease-in-out, width 0.5s ease-in-out;
}

@media (max-width: 480px) {
  #flashlight {
    position: absolute;
    left: -200%;
    top: -200%;

    height: 400%;
    width: 300%;

    background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 0) 3%,
      rgba(0, 0, 0, 0.98) 8%
    ); /*comment that to see all screen*/
    filter: blur(4px);

    transition: left 0.5s ease-in-out, top 0.5s ease-in-out,
      height 0.5s ease-in-out, width 0.5s ease-in-out;
  }
}

@tailwind utilities;
